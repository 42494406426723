export default [
  // {
  //   header: 'Home',
  //   resource: 'Dashboard',
  //   action: 'read',
  // },
  // {
  //   title: 'Dashboard',
  //   route: 'dashboard',
  //   icon: 'PieChartIcon',
  //   resource: 'Dashboard',
  //   action: 'read',
  // },
  {
    header: 'Pembelian',
    resource: 'Transaction',
    action: 'read',
  },
  {
    title: 'Transaksi',
    route: 'transaction',
    icon: 'ShoppingBagIcon',
    resource: 'Transaction',
    action: 'read',
  },
  {
    header: 'Barang',
    resource: 'Product',
    action: 'read',
  },
  {
    title: 'Produk',
    route: 'product',
    icon: 'PackageIcon',
    resource: 'Product',
    action: 'read',
  },
  {
    title: 'Brand',
    route: 'brand',
    icon: 'AwardIcon',
    resource: 'Brand',
    action: 'read',
  },
  {
    header: 'Promosi',
    resource: 'Banner',
    action: 'read',
  },
  {
    title: 'Banner',
    route: 'banner',
    icon: 'ImageIcon',
    resource: 'Banner',
    action: 'read',
  },
  {
    title: 'Voucher',
    route: 'voucher',
    icon: 'TagIcon',
    resource: 'Voucher',
    action: 'read',
  },
  {
    title: 'Sale',
    route: 'sale',
    icon: 'PercentIcon',
    resource: 'Sale',
    action: 'read',
  },
  {
    title: 'Koleksi',
    route: 'collection',
    icon: 'GridIcon',
    resource: 'Collection',
    action: 'read',
  },
  {
    header: 'Master',
    resource: 'Product',
    action: 'read',
  },
  {
    title: 'Pengguna',
    route: 'user',
    icon: 'UserIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Pengaturan',
    route: 'settings',
    icon: 'SettingsIcon',
    resource: 'Settings',
    action: 'read',
  },
  {
    title: 'FAQ',
    icon: 'HelpCircleIcon',
    action: 'read',
    resource: 'QuestionCategory',
    children: [
      {
        title: 'Kategori Pertanyaan',
        route: 'question-category',
        resource: 'QuestionCategory',
        action: 'read',
      },
      {
        title: 'Pertanyaan',
        route: 'question',
        resource: 'Question',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kategori',
    icon: 'FolderIcon',
    action: 'read',
    resource: 'Category',
    children: [
      {
        title: 'Kategori',
        route: 'category',
        resource: 'Category',
        action: 'read',
      },
      {
        title: 'Kategori Induk',
        route: 'parent-category',
        resource: 'ParentCategory',
        action: 'read',
      },
      {
        title: 'Kategori Utama',
        route: 'root-category',
        resource: 'RootCategory',
        action: 'read',
      },
    ],
  },
  {
    title: 'Lainnya',
    icon: 'MoreHorizontalIcon',
    action: 'read',
    resource: 'PaymentMethod',
    children: [
      {
        title: 'Metode Pembayaran',
        route: 'payment-method',
        resource: 'PaymentMethod',
        action: 'read',
      },
    ],
  },
  // {
  //   title: 'Settings',
  //   route: 'settings',
  //   icon: 'SlashIcon',
  //   resource: 'Settings',
  //   action: 'read',
  // },
]
